import Image from "next/image";
import Marquee from "react-fast-marquee";

const TrustUs = () => {
  return (
    <div className="trust-holder">
      <div className="trust-inner uni-width flex-center">
        <p className="text-secondary trusted-title mr-24 body-md-bold">
          Trusted by 4500+ companies
        </p>

        <div className="h-[48px] relative overflow-hidden marquee-holder">
          <div className="marquee-blur"></div>
          <Marquee speed={150} className="marquee">
            <span>
              <Image
                src="/assets/images/irorun.png"
                width={136}
                height={35}
                alt=""
              />
            </span>{" "}
            <span>
              <Image
                src="/assets/images/blockacash.png"
                width={136}
                height={35}
                alt=""
              />
            </span>{" "}
            <span>
              <Image
                src="/assets/images/urgent10k.png"
                width={136}
                height={35}
                alt=""
              />
            </span>{" "}
            <span>
              <Image
                src="/assets/images/snapcash.png"
                width={136}
                height={35}
                alt=""
              />
            </span>{" "}
            <span>
              <Image
                src="/assets/images/a1credit.png"
                width={136}
                height={35}
                alt=""
              />
            </span>{" "}
            <span>
              <Image
                src="/assets/images/kredibank.png"
                width={136}
                height={35}
                alt=""
              />
            </span>{" "}
          </Marquee>

          <div className="right-end"></div>
        </div>
      </div>
    </div>
  );
};

export {TrustUs};
